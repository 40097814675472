import axios, {AxiosInstance} from 'axios';

export function createAxios(): AxiosInstance {
  const instance = axios.create({
    baseURL: 'https://api.wedding-mood.ru',
    // baseURL: 'http://localhost:8080',
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      return Promise.reject(error);
    }
  );

  return instance;
}
