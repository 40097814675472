import {useAppSelector} from 'hooks';
import {mapSelector} from 'store/config/selectors';
import {Map} from 'components';
import image1 from '../../assets/images/rings.png';
import {Menu} from 'app-constants';
import {LeafletMap} from '../LeafletMap';

export function Registration() {
  const map = useAppSelector(mapSelector);

  if (!map?.registration) {
    return null;
  }

  return (
    <div className="registration-wrp" id={Menu.MAP.id}>
      <div className="registration">
        <div className="col-md-6 col-left">
          <div className="registration-text">
            <div className="text-wrp">
              <div className="title wow fadeInLeft">
                {map.registration.title}
              </div>
              <div
                className="text wow fadeInLeft"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{__html: map.registration.address}}
              />
              <img src={image1} alt="кольца" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-right wow fadeIn">
          {/*<Map*/}
          {/*  title={map.registration.title}*/}
          {/*  marker={map.registration.marker}*/}
          {/*/>*/}
          <LeafletMap
            title={map.registration.title}
            marker={map.registration.marker}
          />
        </div>
      </div>
    </div>
  );
}
