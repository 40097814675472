import {CeremonyPlaceType} from 'store/config/types';

interface Props {
  place: CeremonyPlaceType;
}

export function CeremonyPlace({place}: Props) {
  if (!place) {
    return null;
  }
  return (
    <div className="place1 place">
      <div className="title">{place.title}</div>
      <div className="info" dangerouslySetInnerHTML={{__html: place.text}} />
    </div>
  );
}
