export const Menu = {
  OUR_STORY: {
    id: 'history',
    href: '#history',
    title: 'Наша история',
  },
  INVITE: {
    id: 'invite',
    href: '#invite',
    title: 'Приглашение',
  },
  CEREMONY: {
    id: 'ceremony',
    href: '#ceremony',
    title: 'О церемонии',
  },
  GALLERY: {
    id: 'gallery',
    href: '#gallery',
    title: 'Галерея',
  },
  QUESTION: {
    id: 'question',
    href: '#question',
    title: 'Опрос',
  },
  MAP: {
    id: 'map',
    href: '#map',
    title: 'Место проведения',
  },
};
