import photo1 from '../../assets/images/photo1.png';
import photo2 from '../../assets/images/photo2.png';
import photo3 from '../../assets/images/photo3.png';
import photo4 from '../../assets/images/photo4.png';
import {useAppSelector} from 'hooks';
import {historySelector} from 'store/config';
import {Menu} from 'app-constants';

export function History() {
  const history = useAppSelector(historySelector);

  if (!history) {
    return null;
  }

  return (
    <div id={Menu.OUR_STORY.id} className="history-wrp">
      <div className="history">
        <div className="col-md-6 col-left">
          <div className="history-text">
            <div className="text-wrp">
              <div className="title wow fadeInLeft">История знакомства</div>
              <div className="border" />
              <div
                className="text wow fadeInLeft"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{__html: history.she}}
              />
              <div
                className="text wow fadeInLeft"
                data-wow-delay="0.6s"
                dangerouslySetInnerHTML={{__html: history.he}}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-right">
          <div className="row row-top">
            <div
              className="col-md-6 imgLiquidFill imgLiquid imgLiquid_bgSize imgLiquid_ready"
              style={{
                backgroundImage: `url(${photo1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div className="col-md-6 img-center">
              <img src={photo2} alt="" />
            </div>
          </div>
          <div className="row row-bottom">
            <div className="col-md-6 img-center">
              <img src={photo3} alt="" />
            </div>
            <div
              className="col-md-6 imgLiquidFill imgLiquid imgLiquid_bgSize imgLiquid_ready"
              style={{
                backgroundImage: `url(${photo4})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
