import {useCallback, useState} from 'react';
import {RadioButton} from './RadioButton';
import {useQuestionContext} from './hooks';

export function QMain() {
  const {config, onChangeMainQuestion} = useQuestionContext();

  if (!config?.main) {
    return null;
  }

  const [answer, setAnswer] = useState<string>(null);

  const onClickRadio = useCallback(
    (value: string) => () => {
      setAnswer(value);
      onChangeMainQuestion(value);
    },
    []
  );

  return (
    <div className="row inp-radio">
      <RadioButton
        name="visit"
        title={config.main.yes}
        onClick={onClickRadio(config.main.yes)}
        isChecked={answer === config.main.yes}
        className="col-md-6 col-left clear"
      />
      <RadioButton
        name="visit"
        title={config.main.no}
        onClick={onClickRadio(config.main.no)}
        isChecked={answer === config.main.no}
        className="col-md-6 col-right clear"
      />
    </div>
  );
}
