import {RadioProps} from './types';

export function Radio({name, title, onClick, isChecked}: RadioProps) {
  return (
    <div className="quest-radio" onClick={() => onClick(title)}>
      <label className={isChecked ? 'checked' : null}>
        <div
          className="jq-radio radios-check"
          unselectable="on"
          style={{
            userSelect: 'none',
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <input
            type="radio"
            name={name}
            className="radios-check"
            style={{
              userSelect: 'none',
              display: 'inline-block',
              position: 'relative',
            }}
          />
          <div className="jq-radio__div" />
        </div>
        {title}
      </label>
    </div>
  );
}
