import {RadioProps} from './types';

export function RadioButton({
  name,
  title,
  isChecked,
  onClick,
  className,
}: RadioProps) {
  return (
    <div className={className}>
      <label
        className={isChecked ? 'checked' : null}
        onClick={() => onClick(title)}
      >
        {title}
        <div
          className="jq-radio"
          unselectable="on"
          style={{
            userSelect: 'none',
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <input
            type="radio"
            name={name}
            className="btn-radios-check"
            style={{
              userSelect: 'none',
              display: 'inline-block',
              position: 'relative',
            }}
          />
          <div className="jq-radio__div" />
        </div>
      </label>
    </div>
  );
}
