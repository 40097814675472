import {useCallback, useMemo, useState} from 'react';
import {QMain} from './QMain';
import {useAppDispatch, useAppSelector} from 'hooks';
import {
  questionSelector,
  isSendingSelector,
  sendMessageAction,
} from 'store/config';
import {QPool} from './QPool';
import {QuestionContextProvider} from './QuestionContext';
import {QuestionAnswer} from './types';
import {Menu} from 'app-constants';
import {LoadingOutlined} from '@ant-design/icons';
import {FormType} from 'store/config/types';

export function Question() {
  const dispatch = useAppDispatch();
  const config = useAppSelector(questionSelector);
  const isSending = useAppSelector(isSendingSelector);

  if (!config) {
    return null;
  }

  const [mainQuestion, setMainQuestion] = useState<string>(null);
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([]);
  const [vehicleFreeSpace, setVehicleFreeSpace] = useState<string>(null);
  const [contacts, setContacts] = useState<string>('');
  const [contacts5XpoQHFBBwpS3F6NeUEck, setContacts5XpoQHFBBwpS3F6NeUEck] =
    useState<string>('');

  const hasPool = useMemo(() => config.pool?.length > 0, [config]);

  const onChangeQuestion = useCallback((value: QuestionAnswer) => {
    setQuestionAnswers(prev => {
      const find = prev.find(x => x.pool.id === value.pool.id);
      if (find) {
        return [...prev.filter(x => x.pool.text !== find.pool.text), value];
      }
      return [...prev, value];
    });
  }, []);

  const onResetForm = useCallback(() => {
    setContacts('');
    setContacts5XpoQHFBBwpS3F6NeUEck('');
    setQuestionAnswers([]);
    setVehicleFreeSpace(null);
  }, []);

  const onSubmit = useCallback(() => {
    const result: string[] = [];

    if (mainQuestion) {
      result.push(`Присутствие: ${mainQuestion}`);
    }

    if (vehicleFreeSpace !== null) {
      result.push(`Буду на транспорте, свободных мест: ${vehicleFreeSpace}`);
    }

    questionAnswers.forEach(q => {
      const pollResult: string[] = [];
      if (q.answer.variants?.length) {
        pollResult.push(q.answer.variants.join('; '));
      }
      if (q.answer.custom) {
        pollResult.push(q.answer.custom);
      }
      if (pollResult.length) {
        result.push(`${q.pool.text}: ${pollResult.join('; ')}`);
      }
    });

    const payload: FormType = {};

    if (contacts) {
      result.push(`Имя/Контакты (honeypot): ${contacts}`);
      payload.contacts = contacts;
    }

    if (contacts5XpoQHFBBwpS3F6NeUEck) {
      result.push(`Имя/Контакты: ${contacts5XpoQHFBBwpS3F6NeUEck}`);
      payload.contacts5XpoQHFBBwpS3F6NeUEck = contacts5XpoQHFBBwpS3F6NeUEck;
    }
    payload.message = result.join('<br/>');
    dispatch(sendMessageAction(payload));
    onResetForm();
  }, [
    mainQuestion,
    vehicleFreeSpace,
    questionAnswers,
    contacts,
    contacts5XpoQHFBBwpS3F6NeUEck,
    onResetForm,
  ]);

  const disabled = useMemo(
    () => !contacts5XpoQHFBBwpS3F6NeUEck || !mainQuestion || isSending,
    [contacts5XpoQHFBBwpS3F6NeUEck, mainQuestion, isSending]
  );

  return (
    <QuestionContextProvider
      value={{
        config,
        mainQuestion,
        onChangeMainQuestion: setMainQuestion,
        questionAnswers,
        onChangePool: onChangeQuestion,
      }}
    >
      <div id={Menu.QUESTION.id} className="questioning-wrp">
        <div className="questioning">
          <div className="title wow fadeIn">Опрос</div>
          <form>
            <QMain />
            <div className="info wow fadeIn" data-wow-delay="0.3s">
              Пожалуйста, чтобы все прошло идеально, ответьте еще на несколько
              вопросов
            </div>
            <div className="row radios wow fadeIn" data-wow-delay="0.6s">
              {hasPool &&
                config.pool.map(pool => <QPool key={pool.id} pool={pool} />)}
            </div>
            {config.vehicleFreeSpace && (
              <div className="info wow fadeIn" data-wow-delay="0.9s">
                Если вы едете на своем авто, укажите количество свободных мест{' '}
                <input
                  type="number"
                  name="freecar"
                  min={0}
                  max={50}
                  onChange={e => {
                    setVehicleFreeSpace(e.target.value);
                  }}
                />
              </div>
            )}

            <div className="row submit wow fadeIn" data-wow-delay="1.2s">
              <div className="honey">
                <input
                  value={contacts}
                  maxLength={50}
                  type="text"
                  name="contact"
                  placeholder="телефон или почта"
                  onChange={e => {
                    setContacts(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-left clear">
                <input
                  value={contacts5XpoQHFBBwpS3F6NeUEck}
                  maxLength={50}
                  name="contact5XpoQHFBBwpS3F6NeUEck"
                  type="text"
                  placeholder="телефон или почта"
                  onChange={e => {
                    setContacts5XpoQHFBBwpS3F6NeUEck(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-right clear">
                <button type="button" disabled={disabled} onClick={onSubmit}>
                  Подтвердить &nbsp;
                  {isSending && <LoadingOutlined />}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </QuestionContextProvider>
  );
}
