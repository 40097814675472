import {Radio} from './Radio';
import {useCallback, useMemo} from 'react';
import {QPoolProps} from './types';
import {useQuestionContext} from './hooks';
import {RadioCustom} from './RadioCustom';

export function QPool({pool}: QPoolProps) {
  const {onChangePool, questionAnswers} = useQuestionContext();

  const onClickRadio = useCallback(
    (value: string) => {
      onChangePool({
        pool,
        answer: {
          variants: [value],
          custom: null,
        },
      });
    },
    [pool]
  );

  const onClickOtherRadio = useCallback(
    (value: string) => {
      onChangePool({
        pool,
        answer: {
          variants: null,
          custom: value,
        },
      });
    },
    [pool]
  );

  const poolAnswers = useMemo(() => {
    if (!questionAnswers?.length) {
      return null;
    }
    return questionAnswers.find(qa => qa.pool.text === pool.text);
  }, [pool, questionAnswers]);

  const hasVariants = useMemo(() => pool.variants?.length > 0, [pool]);

  const hasCustom = useMemo(() => pool.withCustom, [pool]);

  const name = useMemo(() => `poll_${pool.id}`, [pool]);

  const onChangeCustom = useCallback((custom: string) => {
    onChangePool({
      pool,
      answer: {
        variants: null,
        custom,
      },
    });
  }, []);

  return (
    <>
      <div className="col-md-4 car clear">
        <div className="name">{pool.text}</div>
        {hasVariants &&
          pool.variants.map(variant => {
            const isChecked = poolAnswers?.answer.variants?.includes(variant);
            return (
              <Radio
                key={variant}
                name={name}
                title={variant}
                onClick={onClickRadio}
                isChecked={isChecked}
              />
            );
          })}
        {hasCustom && (
          <RadioCustom
            key={`${pool.id}_custom`}
            name={name}
            title="Другое"
            onClick={onClickOtherRadio}
            onChange={onChangeCustom}
            isChecked={!!poolAnswers?.answer.custom}
          />
        )}
      </div>
    </>
  );
}
