import image1 from '../../assets/images/bottle.png';
import {Map} from 'components';
import {useAppSelector} from 'hooks';
import {mapSelector} from 'store/config/selectors';
import {LeafletMap} from '../LeafletMap';

export function Banquet() {
  const map = useAppSelector(mapSelector);

  if (!map?.banquet) {
    return null;
  }

  return (
    <div className="banket-wrp" id="map">
      <div className="banket">
        <div
          className="col-md-6 col-left wow fadeIn"
          style={{position: 'relative'}}
        >
          {/*<Map title={map.banquet.title} marker={map.banquet.marker} />*/}
          <LeafletMap title={map.banquet.title} marker={map.banquet.marker} />
        </div>
        <div className="col-md-6 col-right">
          <div className="banket-text">
            <div className="text-wrp">
              <div className="title wow fadeInRight">{map.banquet.title}</div>
              <div
                className="text wow fadeInRight"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{__html: map.banquet.address}}
              />
              <img src={image1} alt="bottle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
