import {useContext} from 'react';
import {QuestionContext} from '../QuestionContext';

export function useQuestionContext() {
  const data = useContext(QuestionContext);
  if (!data) {
    throw new Error(
      'Нельзя использовать useQuestionContext без QuestionContextProvider '
    );
  }
  return data;
}
