import React from 'react';
import './App.scss';
import './App.less';
import './app1.css';
// import 'antd/dist/antd.min.css';
// import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from 'store';
import ReactDOM from 'react-dom';
import {App, AppConfiguration} from './app';
import 'moment/locale/ru';
import 'leaflet/dist/leaflet.css';

ReactDOM.render(
  <Provider store={store}>
    <AppConfiguration>
      <App />
    </AppConfiguration>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
