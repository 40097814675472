import {
  MapContainer,
  Marker as LeafletMarker,
  Popup,
  TileLayer,
} from 'react-leaflet';
import styles from './LeafletMap.scss';
import {Marker} from 'store/config/types';
import IconMarker from './marker.png';
import L from 'leaflet';

interface Props {
  marker: Marker;
  title: string;
}

export function LeafletMap({title, marker}: Props) {
  const icon = L.icon({
    iconSize: [30, 40],
    iconUrl: require('./marker.png'),
  });

  return (
    <div className={styles.mapWrapper} style={{width: '100%', height: 400}}>
      <MapContainer
        center={marker.point}
        className={styles.map}
        style={{width: '100%', height: '100%'}}
        zoom={marker.zoom}
        minZoom={10}
        maxZoom={20}
        scrollWheelZoom={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LeafletMarker icon={icon} position={marker.point}>
          <Popup>{title}</Popup>
        </LeafletMarker>
      </MapContainer>
    </div>
  );
}
