import bg from '../../assets/images/header-bg.png';

export function Parallax() {
  return (
    <div
      className="parallax-mirror"
      style={{
        visibility: 'visible',
        zIndex: -100,
        position: 'fixed',
        top: '0px',
        left: '0px',
        overflow: 'hidden',
        transform: 'translate3d(0px, 0px, 0px)',
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/*<img*/}
      {/*  src={bg}*/}
      {/*  style={{*/}
      {/*    transform: 'translate3d(0px, 0px, 0px)',*/}
      {/*    position: 'absolute',*/}
      {/*    // top: '-273px',*/}
      {/*    left: '0px',*/}
      {/*    height: '100vh',*/}
      {/*    width: '100%',*/}
      {/*    maxWidth: 'none',*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  );
}
