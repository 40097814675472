import {useAppSelector} from 'hooks';
import {
  weddingInfoSelector,
  leftMenuSelector,
  rightMenuSelector,
} from 'store/config';
import {useMemo, useState} from 'react';
import moment from 'moment';
import {CloseOutlined, MenuOutlined} from '@ant-design/icons';
import cn from 'classnames';

const DATE_CONFIG_FORMAT = 'DD.MM.YYYY';
const DATE_UI_FORMAT = 'DD MMMM YYYY';

export function Header() {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  const info = useAppSelector(weddingInfoSelector);
  const leftMenu = useAppSelector(leftMenuSelector);
  const rightMenu = useAppSelector(rightMenuSelector);

  const menu = useMemo(() => [...leftMenu, ...rightMenu], [leftMenu]);

  const date = useMemo(() => {
    if (!info.date) {
      return null;
    }
    return moment(info.date, DATE_CONFIG_FORMAT)
      .locale('ru')
      .format(DATE_UI_FORMAT);
  }, [info]);

  return (
    <div
      id="header"
      className="header-wrp wrapper-full clear parallax-window"
      data-parallax="scroll"
      data-image-src="img/header-bg.png"
    >
      <MenuOutlined
        className="mobile-menu"
        onClick={() => {
          setIsOpenMobileNav(true);
        }}
      />
      <div
        className={cn(
          'mobile-nav',
          isOpenMobileNav ? 'open-menu' : 'closed-menu'
        )}
      >
        <CloseOutlined
          onClick={() => {
            setIsOpenMobileNav(false);
          }}
        />
        <ul>
          {menu.map(item => (
            <li key={item.id}>
              <a href={item.href} onClick={() => setIsOpenMobileNav(false)}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="header">
        <div className="menu-wrp clear">
          <div className="menu">
            <div className="row ">
              <div className="col-sm-6 col-left clear">
                <ul className="clear float-right">
                  {leftMenu.map((lmenu, index) => (
                    <li key={lmenu.id}>
                      <a
                        href={lmenu.href}
                        className="toplink wow fadeInDown"
                        data-wow-delay={`0.${index * 2}s`}
                      >
                        {lmenu.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-6 col-right clear">
                <ul className="clear">
                  {rightMenu.map((rmenu, index) => (
                    <li key={rmenu.id}>
                      <a
                        href={rmenu.href}
                        className="toplink wow fadeInDown"
                        data-wow-delay={`0.${(index + 2) * 2}s`}
                      >
                        {rmenu.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="logo" />
          </div>
        </div>
        <div className="title wow fadeIn">
          {info.she} и<br /> <span>{info.he}</span>
        </div>
        <div className="info">
          <div className="text wow fadeIn" data-wow-delay="0.3s">
            Наша свадьба
          </div>
          <div className="date wow fadeIn" data-wow-delay="0.6s">
            {date}
          </div>
        </div>
      </div>
    </div>
  );
}
