import {Col, Image, Row} from 'antd';
import {useAppSelector} from 'hooks';
import {gallerySelector} from 'store/config/selectors';
import './Story.scss';
import {Menu} from 'app-constants';

function assertsGallery(value: unknown): asserts value is string[] {
  if (!Array.isArray(value)) {
    throw new Error('Gallery is NOT array of strings');
  }
}

export function Story() {
  const gallery = useAppSelector(gallerySelector);

  if (!gallery || !gallery?.length) {
    return null;
  }

  assertsGallery(gallery);

  return (
    <Row className="section-story" id={Menu.GALLERY.id}>
      <Col
        span={24}
        lg={{span: 20, offset: 2}}
        xl={{span: 16, offset: 4}}
        xxl={{span: 12, offset: 6}}
      >
        <h2 className="wow fadeIn">
          <span>Love story</span>
        </h2>

        <div className="section-story-col">
          <Image.PreviewGroup>
            {gallery.map((image, index) => (
              <Image
                className="wow fadeIn"
                data-wow-delay={`${(index + 1) * 0.2}s`}
                key={image}
                width={245}
                height={245}
                src={image}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      </Col>
    </Row>
  );
}
