import {RadioProps} from './types';

export function RadioCustom({
  name,
  title,
  onClick,
  onChange,
  isChecked,
}: RadioProps) {
  return (
    <>
      <div className="quest-radio" onClick={() => onClick(title)}>
        <label className={isChecked ? 'checked' : null}>
          <div
            className="jq-radio radios-check"
            unselectable="on"
            style={{
              userSelect: 'none',
              display: 'inline-block',
              position: 'relative',
            }}
          >
            <input
              type="radio"
              name={name}
              className="radios-check"
              style={{
                userSelect: 'none',
                display: 'inline-block',
                position: 'relative',
              }}
            />
            <div className="jq-radio__div" />
          </div>
          {title}
        </label>
      </div>
      {isChecked && (
        <div className="quest-text">
          <label>
            <input
              maxLength={50}
              onChange={e => {
                onChange(e.target.value);
              }}
              type="text"
              name="group3-other"
              className="text-check"
            />
          </label>
        </div>
      )}
    </>
  );
}
