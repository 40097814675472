import React, {useEffect} from 'react';
import {Home} from 'views';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';

export function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      mobile: false,
    }).init();
  }, []);

  return <Home />;
}
