import dev from '../../assets/images/dev.png';
import {useAppSelector} from 'hooks';
import {siteSelector, weddingInfoSelector} from 'store/config';

export function Footer() {
  const info = useAppSelector(weddingInfoSelector);
  const site = useAppSelector(siteSelector);

  if (!site || !info) {
    return null;
  }

  return (
    <div className="footer-wrp">
      <div className="footer">
        <p className="wow fadeIn">
          {info.he} ❤ {info.she}
        </p>
        <p className="wow fadeIn" data-wow-delay="0.4s">
          Свадебные приглашения онлайн
        </p>
        <p className="wow fadeIn" data-wow-delay="0.8s">
          Создано на &nbsp;
          <a href={site.domain} target="_blank" rel="noreferrer">
            {site.name}
          </a>
        </p>
      </div>
    </div>
  );
}
