import {inviteSelector} from 'store/config/selectors';
import {useAppSelector} from 'hooks';
import photo6 from '../../assets/images/photo6.png';
import end from '../../assets/images/end.png';
import {Menu} from 'app-constants';

export function Invite() {
  const invite = useAppSelector(inviteSelector);

  if (!invite) {
    return null;
  }
  return (
    <div id={Menu.INVITE.id} className="inviting-wrp">
      <div className="inviting">
        <div className="col-md-6 col-left clear">
          <div className="inviting-text">
            <div className="text-wrp">
              <div
                className="title wow fadeInLeft"
                dangerouslySetInnerHTML={{__html: invite.title}}
              />
              <div
                className="text wow fadeInLeft"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{__html: invite.text}}
              />
              <img
                className="end wow fadeInLeft"
                data-wow-delay="0.6s"
                src={end}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-right imgLiquidFill imgLiquid imgLiquid_bgSize imgLiquid_ready"
          style={{
            backgroundImage: `url(${photo6})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
    </div>
  );
}
