import photo5 from '../../assets/images/photo5.png';
import {useAppSelector} from 'hooks';
import {ceremonySelector} from 'store/config';
import {CeremonyPlace} from './CeremonyPlace';
import {Menu} from 'app-constants';

export function Ceremony() {
  const ceremony = useAppSelector(ceremonySelector);

  if (!ceremony) {
    return null;
  }

  return (
    <div id={Menu.CEREMONY.id} className="ceremony-wrp">
      <div className="ceremony">
        <div
          className="col-md-6 col-left imgLiquidFill imgLiquid imgLiquid_bgSize imgLiquid_ready"
          style={{
            backgroundImage: `url(${photo5})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="col-md-6 col-right clear">
          <div className="ceremony-text">
            <div className="text-wrp">
              <div className="title wow fadeInRight">{ceremony.title}</div>
              <div className="border" />
              <div
                className="info wow fadeInRight"
                data-wow-delay="0.3s"
                dangerouslySetInnerHTML={{__html: ceremony.subTitle}}
              />
              <div className="places wow fadeInRight" data-wow-delay="0.6s">
                {ceremony.places.map(place => (
                  <CeremonyPlace place={place} key={place.title} />
                ))}
              </div>
              <div
                className="text wow fadeInRight"
                data-wow-delay="0.9s"
                dangerouslySetInnerHTML={{__html: ceremony.footer}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
