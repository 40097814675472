import {
  Parallax,
  Header,
  History,
  Ceremony,
  Invite,
  Story,
  Registration,
  Banquet,
  Footer,
} from 'components';
import {Question} from 'components/Question';

export function Home() {
  return (
    <>
      <Parallax />
      <Header />
      <History />
      <Ceremony />
      <Invite />
      <Question />
      <Story />
      <Registration />
      <Banquet />
      <Footer />
    </>
  );
}
